import React from 'react';
import Navbar from './components/navbar';
import Footer from './components/footer';
import './App.css'; // Import your CSS file
import Second from './second';
import { useNavigate, Routes, Route } from 'react-router-dom';



function App() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/second');
  };

  const links = [
    { url: '/second', text: 'FitGenie' },
    { url: 'https://sahilsawant.000.pe', text: 'Contact' }
  ];

  return (
    <div className="App">
      <Routes>
        <Route path="/second" element={<Second />} />
        {/* Define other routes as needed */}
      </Routes>
      <Navbar links={links} />
      <div className="main-container">
        <div className='heading-container'>
          <h1 className="main-txt">FIT<span style={{color:"white"}}>FRENZY</span></h1>
          <p className="sub-text">Empowering Your Fitness Journey with Personalized Guidance, Expert Advice, and Unwavering Support</p>
          <button className='try-button' onClick={handleNavigate} >
          Try FitGenie Now! →
          </button>
        </div>
      </div>
      <div className='about-div'>
        <h1 className='about-head'>Introducing FitGenie</h1>
        <p className='about-text'>FitGenie is your go-to fitness assistant; it offers customized workout regimens, dietary programs, suggested supplements, advise on rest and recovery, and more. FitGenie is equipped with the expertise and knowledge needed to assist you in reaching your fitness goals. Unlock your potential now and say hi to your new fitness partner!</p>
      </div>
      <div className='features-container'>
        <div className='features-div'>
        <h1 className='features-head'>Features</h1>
        <hr className='custom-hr'/>
        </div>
        <div className='features-div'> 
          <img src='images/diet.webp' alt='supplements' className='features-icon'/>
          <p className='features-text'>Achieve optimal nutrition and fuel your body for success with our Diet Planner. With the help of our Diet Planner, which is customized to your food choices, nutritional needs, and fitness goals, you can make healthy meal plans that help you reach your objectives. Take charge of your diet to reach your greatest potential, from macro tracking to meal planning.</p>
        </div>
        <div className='features-div'> 
          <p className='features-text'>Say goodbye to guesswork and hello to structured workouts with our simple Exercise Planner. Make workout plans that are specific to your goals, degree of fitness, and the equipment you have access to. Whether your goals are to gain more strength, endurance, or flexibility, our Workout Planner offers a feature-rich platform that makes it simple to plan your exercises.</p>
          <img src='images/exercise.webp' alt='supplements' className='features-icon'/>
        </div>
        <div className='features-div'> 
          <img src='images/whey.webp' alt='supplements' className='features-icon'/>
          <p className='features-text'>Find specialized supplement recommendations designed to maximize your training and promote your advancement. Our advanced algorithm assesses your nutritional requirements, food choices, and exercise objectives to provide a customized selection of supplements that work well with your routine. Discover the ideal supplements to support your fitness journey, from pre-workout enhancers to post-recovery essential.</p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
