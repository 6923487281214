import React, { useState, useEffect, useRef } from 'react';
import './chatScreen.css'; // Ensure CSS is correctly linked
import { v4 as uuidv4 } from 'uuid';
import Navbar from './components/navbar';

const referenceQuestions = {
  eng: [
    "What is the best workout routine for building muscle mass?",
    "Can you suggest a personalized diet plan to help me lose weight?",
    "What supplements do you recommend for improving athletic performance?"
  ],
}


function markdownToHtml(markdown) {
  return markdown
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Converts **text** to <strong>text</strong>
    .replace(/\n/g, '<br>'); // Converts newlines to <br>
}

const ChatScreen = () => {

  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const [feedback, setFeedback] = useState(null);
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const feedbackTimeout = useRef(null);

  const [messageSent, setMessageSent] = useState(false);

  const [sessionUUID, setSessionUUID] = useState('');

  const [isRef, setIsRef] = useState(false);

  useEffect(() => {
    // Generate a UUID for this session
    const newUUID = uuidv4();
    setSessionUUID(newUUID);
  }, []);

  // 
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Reference Questions

  const handleReferenceQuestionClick = (refMessage) => {
    handleSend(refMessage);
    setIsRef(true);
  };

  // Feedback Section
  const sendFeedback = (sessionUUID, feedback) => {
    let formData = new FormData();
    formData.append('feedback', feedback);
    formData.append('session_uuid', sessionUUID);


    fetch('http://Dabster.pythonanywhere.com/send_feedback', {
      method: 'POST',
      body: formData
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then(() => {
        setFeedbackMessage('Feedback sent successfully');
      })
  };

  // Send Message
  const sendMessage = (sessionUUID, message, feedback) => {
    setIsRef(true);
    setLoading(true);
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append('session_uuid', sessionUUID);
      formData.append('message', message);
      formData.append('feedback', feedback);

      fetch('http://Dabster.pythonanywhere.com/send_message', { // Ensure this is your correct server URL
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setLoading(false);
          let htmlResponse = markdownToHtml(data.response);
          setMessages(prevMessages => [
            ...prevMessages,
            { html: htmlResponse, sender: 'bot', id: Date.now().toString() },
          ]);
          // startPlaying();
          resolve();
        })
        .catch((error) => {
          setLoading(false);
          console.error('Fetch error:', error);
          reject(error);
        });
    });
  };


  const handleSend = (customMessage = null) => {

    // If a message is already being processed, return early
    if (loading || (message.trim() === '' && !customMessage)) {
      return;
    }

    setFeedbackGiven(false);
    setMessageSent(true);

    const finalMessage = customMessage || message;

    setMessages(prevMessages => [
      ...prevMessages,
      { text: finalMessage, sender: 'user', id: Date.now().toString() },
    ]);
    setMessage('');

    setLoading(true);

    sendMessage(sessionUUID, finalMessage, feedback)
      .then(() => {
        setTimeout(() => {
          setLoading(false);
          setFeedback(null); // Reset feedback after sending the message
        }, 1);
      })
      .catch(() => {
        setLoading(false);
        // Handle error if needed
      });
  };

  const handleFeedback = (feedback) => {
    const feedbackValue = feedback === '👍' ? 'good' : feedback === '👎' ? 'bad' : 'null';
    sendFeedback(sessionUUID, feedbackValue);
    setFeedbackGiven(true);
    setFeedbackMessage('Feedback received. Thank you!');

    // Clear any existing timeout
    clearTimeout(feedbackTimeout.current);

    // Set a new timeout to clear the feedback message
    feedbackTimeout.current = setTimeout(() => {
      setFeedbackMessage('');
      setFeedbackGiven(true);
    }, 180000); // 4 seconds, adjusted comment to match the code
  };


  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && message.trim() !== '') {
      handleSend();
    }
  };


  const adjustTextAreaHeight = (element) => {
    element.style.height = 'auto'; // Reset the height to shrink or expand as needed
    if (element.value === '') {
      // If the textarea is empty, reset to the minimum height
      element.style.height = '20px'; // Adjust '20px' to your desired minimum height
    } else {
      // If the textarea has content, adjust to fit that content
      element.style.height = `${element.scrollHeight}px`;
    }
  };

  const links = [
    { url: '/', text: 'Home' },
    { url: 'https://sahilsawant.000.pe', text: 'Contact' }
  ];

  return (
    <div className='second'>
      {/* <div className='nav-conatiner'>
        <h1 className='nav-head'>Fit<span style={{ color: "white" }}>Frenzy</span></h1>
      </div> */}
      <Navbar links={links} />
      <div className='main-div'>
        {!isRef && <img src='images/genie.webp' alt='logo' className='main-img' />}
        {messages.map((item, index) => (
          <div key={index} className="message-container">
            <div style={{ display: 'flex', marginTop: 10 }}>
              <div className={item.sender === 'user' ? 'user-message' : 'bot-message'}>
                {item.sender === 'user' ? item.text : <p className='message-text' dangerouslySetInnerHTML={{ __html: item.html }} />}
                {/* Show feedback buttons only for bot's message and when not loading */}
                {item.sender === 'bot' && !loading && item.id === messages[messages.length - 1]?.id && (
                  feedbackGiven ? (
                    <p className='feedback-text'>{feedbackMessage}</p>
                  ) : (
                    <div className="feedback-buttons">
                      <img
                        src="./images/like.webp"
                        className="feedback-icon"
                        onClick={() => handleFeedback('👍')}
                        alt="Like"
                      />
                      <img
                        src="./images/dislike.webp"
                        className="feedback-icon"
                        onClick={() => handleFeedback('👎')}
                        alt="Dislike"
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            {/* Show loader after user's message while waiting for bot's response */}
            {item.sender === 'user' && loading && index === messages.length - 1 && (
              <div className="card">
                <div className="card__skeleton card__title"></div>
                <div className="card__skeleton card__description"></div>
              </div>
            )}
          </div>
        ))}
      </div>
      {/* Chat Input */}
      <div className='fixed-bottom'>
        {!messageSent && (
          <div className='reference-div row justify-content-center'>
            {referenceQuestions["eng"]?.map((question, index) => (
              <div
                key={index}
                className='question-div col-4 d-flex justify-content-center align-items-center'
                onClick={() => handleReferenceQuestionClick(question)}
              >
                <p className='ref-text'>{question} →</p>
              </div>
            ))}
          </div>
        )}
        <div className="chat-input-container">
          <textarea
            placeholder='Ask a question'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onInput={(e) => adjustTextAreaHeight(e.target)}
            onKeyPress={handleKeyPress}
            className="chat-input"
            style={{ height: 20 }}
          />
          <button onClick={() => {
            handleSend();
            setFeedbackGiven(false);
          }}
            disabled={loading}
            style={{ opacity: loading ? 0.5 : 1, marginLeft: 10 }}
          >
            <img className="send-icon" src="images/send.webp" alt="Send" />
          </button>
        </div>
        <p className='bottom-text'>"FitGenie's input is typically beneficial, but it's advisable to confirm its accuracy."</p>
      </div>
    </div>
  );
};


export default ChatScreen;
