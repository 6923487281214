import React from 'react';
import './footer.css';

const Footer = () => {
    return (
        <div>
            <hr style={{width:"80%", margin:"auto"}}/>
            <div className='footer-container'>
                <h1 className='footer-head'>Fit<span style={{ color: 'white' }}>Frenzy</span></h1>
                <div className='icon-div'>
                    <img className='footer-icon' alt='facebook' src='images/facebook.webp' />
                    <img className='footer-icon' alt='facebook' src='images/linkedin.webp' />
                    <img className='footer-icon' alt='facebook' src='images/github.webp' />
                </div>
            </div>
        </div>
    );
};

export default Footer;