import React from 'react';
import './navbar.css';

const Navbar = ({ links }) => {
  return (
    <nav className="navbar navbar-expand-lg bg-black fixed-top" id="mainNav">
        <div className="container px-4">
            <a className="navbar-brand nav-h" href="#sahil-sawant"><span style={{color:"#ff3131"}}>Fit</span>Frenzy</a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
                <ul className="navbar-nav ms-auto">
                    {links.map((link, index) => (
                        <li className="nav-item" key={index}><a className="nav-link nav-txt" href={link.url}>{link.text}</a></li>
                    ))}
                </ul>
            </div>
        </div>
    </nav>
  );
};

export default Navbar;
